import { Permission } from '@campxdev/shared'
import { EvaluationPercentageDifferenceReport, ScrutinyReport } from 'pages'

export const individualRoutes = [
  {
    path: 'subject-bundle-scrutiny-report',
    element: <ScrutinyReport />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'subject-bundle-evaluation-difference-report',
    element: <EvaluationPercentageDifferenceReport />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  ,
]
